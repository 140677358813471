import { SyntheticEvent, useState, ReactNode, useEffect } from 'react';
import { Box, Typography, Tab, Tabs, Grid } from '@mui/material';
import { TabSystemPrice } from '@/shared/constant/common';
import { useSearchParams } from 'react-router-dom';
import CentralAndNorthPrice from './CommonPriceSystem/CentralAndNorthPrice';
import SouthPrice from './CommonPriceSystem/SouthPrice';
import TitlePage from '@/components/TitlePage';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
  };
}

export default function TabSystemPriceComponents() {
  const [value, setValue] = useState<number>(0);

  const tabData = [{ component: <CentralAndNorthPrice /> }, { component: <SouthPrice /> }];

  const lang = window.lang;
  const [searchParams] = useSearchParams();
  const isSouth = searchParams.get('isSouth');

  useEffect(() => {
    if (isSouth) setValue(1); // tab miền bắc - trung
    else setValue(0); // tab miền nam
  }, [isSouth]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container className="page-title border-button-back" mb={1}>
        <Grid item>
          <TitlePage title={lang.areaPriceSetting.areaPriceTitle} />
        </Grid>
      </Grid>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            {TabSystemPrice.map((tab, index) => (
              <Tab className="fz-14" key={index} label={tab.label} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {tabData.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </Box>
    </>
  );
}
