import { Card, FormControl, Grid, Typography, useMediaQuery } from '@mui/material';
import { SelectSearch } from '@/common/Form';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CONST_ROUTER, NOT_PAGE_SIZE } from '@/shared/constant/common';
import { BackPage } from '@/components/BackPage';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { InputPriceForm } from '@/common/Form/inputPrice';
import { useEffect } from 'react';
import { HandleButtonAddEdit } from '@/components/HandleButtonAddEdit';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPriceSystem,
  getPriceSystemList,
  clearPriceSystemDetail,
  getPriceSystemDetail,
  getProvinceList,
  priceSystemData,
  putEditPriceSystem,
} from '@/redux/slices/priceSystem';
import LoadingPage from '@/components/LoadingPage';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { MultiSelectAutocomplete } from './MultiSelectProvince';
import { createNewPath, customDataProvince, optionsDataApiProvince, pushAllOptions } from '@/shared/utils/common';
import { getVehicleClass, vehicleClassData } from '@/redux/slices/vehicleClass';

export default function AddAreaPrice() {
  const schemaAddAreaPrice = (lang) =>
    yup.object().shape({
      provinceId: yup
        .array()
        .of(yup.string().required('Khu vực không được để trống'))
        .min(1, 'Cần chọn ít nhất một tỉnh'),
      vehicle: yup.string().nullable().required('Hạng xe không được để trống'),
      firstPrice: yup
        .string()
        .required(lang.areaPriceSetting.priceRequired)
        .matches(/^[^.]*$/, lang.schema.payment.special)
        .test('is-positive', lang.schema.payment.min, (value) => {
          const priceString = String(value);
          const price = Number(priceString.replace(/\./g, ''));
          return +price >= 0;
        }),
      middlePrice: yup
        .string()
        .required(lang.areaPriceSetting.priceRequired)
        .matches(/^[^.]*$/, lang.schema.payment.special)
        .test('is-positive', lang.schema.payment.min, (value) => {
          const priceString = String(value);
          const price = Number(priceString.replace(/\./g, ''));
          return +price >= 0;
        }),
      finalPrice: yup
        .string()
        .required(lang.areaPriceSetting.priceRequired)
        .matches(/^[^.]*$/, lang.schema.payment.special)
        .test('is-positive', lang.schema.payment.min, (value) => {
          const priceString = String(value);
          const price = Number(priceString.replace(/\./g, ''));
          return +price >= 0;
        }),
      surcharge: yup
        .string()
        .required(lang.areaPriceSetting.priceRequired)
        .matches(/^[^.]*$/, lang.schema.payment.special)
        .test('is-positive', lang.schema.payment.min, (value) => {
          const priceString = String(value);
          const price = Number(priceString.replace(/\./g, ''));
          return +price >= 0;
        }),
    });
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch<any>();
  const ALL_VEHICLE = '1';

  const dataSelector = useSelector(priceSystemData);
  const isLoading = dataSelector?.loadingPriceSystemDetail && dataSelector?.loadingListProvince;
  const lang = window.lang;
  const { itemId } = useParams();
  const [searchParams] = useSearchParams();
  const isSouth = searchParams.get('isSouth');
  const textConfigKm = isSouth ? '15km' : '20km';

  const dataApiProvince = dataSelector?.listProvince;
  const dataApiDetail = dataSelector?.priceSystemDetail.data;

  const pathSystemPrice = createNewPath(CONST_ROUTER.SYSTEM_PRICE_SETTING, {
    isSouth,
  });

  const handleBackPage = () => {
    navigate(pathSystemPrice);
  };

  const CompareData = (input, dataApi) => {
    if (typeof dataApi === 'number') {
      return watch(input) === dataApi || watch(input) === dataApi.toString() ? true : false;
    }
    return watch(input) === dataApi ? true : false;
  };

  const { listVehicle } = useSelector(vehicleClassData) || {};
  const { data: dataVehicle } = listVehicle || {};

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaAddAreaPrice(lang)),
    defaultValues: {
      provinceId: [],
      firstPrice: undefined,
      middlePrice: undefined,
      finalPrice: undefined,
      surcharge: undefined,
      vehicle: ALL_VEHICLE,
    },
  });
  const defaultValue = {
    textSearch: null,
  };

  const valueVehicle = (data) => {
    if (!data) return ALL_VEHICLE;
    return data;
  };

  useEffect(() => {
    dispatch(getProvinceList(defaultValue));
    dispatch(getVehicleClass({ page: 1, take: NOT_PAGE_SIZE }));

    if (itemId) {
      dispatch(getPriceSystemDetail(itemId));
    }
  }, []);

  const handleCancel = () => {
    if (!itemId) {
      setValue('provinceId', []);
      setValue('firstPrice', '');
      setValue('middlePrice', '');
      setValue('finalPrice', '');
      setValue('surcharge', '');
      setValue('vehicle', '');
    } else {
      setValue('provinceId', dataApiDetail?.provinceId);
      setValue('firstPrice', dataApiDetail?.firstPrice);
      setValue('middlePrice', dataApiDetail?.middlePrice);
      setValue('finalPrice', dataApiDetail?.finalPrice);
      setValue('surcharge', dataApiDetail?.surcharge);
      setValue('vehicle', valueVehicle(dataApiDetail?.vehicleClassId));
    }
    clearErrors();
  };

  const isNewData =
    CompareData('provinceId', dataApiDetail?.provinceId) &&
    CompareData('firstPrice', dataApiDetail?.firstPrice) &&
    CompareData('finalPrice', dataApiDetail?.finalPrice) &&
    CompareData('surcharge', dataApiDetail?.surcharge) &&
    CompareData('middlePrice', dataApiDetail?.middlePrice) &&
    CompareData('vehicle', dataApiDetail?.vehicleClassId);

  useEffect(() => {
    if (itemId && dataApiDetail) {
      setValue('provinceId', [dataApiDetail.provinceId]);
      setValue('firstPrice', dataApiDetail.firstPrice);
      setValue('middlePrice', dataApiDetail.middlePrice);
      setValue('finalPrice', dataApiDetail.finalPrice);
      setValue('surcharge', dataApiDetail.surcharge);
      setValue('vehicle', valueVehicle(dataApiDetail.vehicleClassId));
    }
    return () => {
      dispatch(clearPriceSystemDetail);
    };
  }, [itemId, dataApiDetail]);

  const convertStringToNumber = (value) => {
    return Number(value.replace(/,/g, ''));
  };
  const onSubmit = (data) => {
    const vehicleClassId = data.vehicle === ALL_VEHICLE ? null : data.vehicle;
    const params = {
      firstPrice: convertStringToNumber(data.firstPrice),
      middlePrice: convertStringToNumber(data.middlePrice),
      finalPrice: convertStringToNumber(data.finalPrice),
      surcharge: convertStringToNumber(data.surcharge),
      provinceIds: data.provinceId,
      vehicleClassId,
    };
    const payload = {
      params: params,
      callbackSuccess: () => {
        showSuccessToast(lang.areaPriceSetting.successAdd);
        reset();
        navigate(pathSystemPrice);
        dispatch(getPriceSystemList({ isSouth: isSouth ? true : false }));
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(addPriceSystem(payload));
  };

  const handleSave = (data) => {
    const vehicleClassId = data.vehicle === ALL_VEHICLE ? null : data.vehicle;
    const params = {
      firstPrice: convertStringToNumber(data.firstPrice),
      middlePrice: convertStringToNumber(data.middlePrice),
      finalPrice: convertStringToNumber(data.finalPrice),
      surcharge: convertStringToNumber(data.surcharge),
      provinceIds: data?.provinceId,
      vehicleClassId,
      id: itemId,
    };
    const payload = {
      params: params,
      callbackSuccess: (isError) => {
        showSuccessToast(lang.areaPriceSetting.successEdit);
        itemId && dispatch(getPriceSystemDetail(itemId));
        reset();
        navigate(pathSystemPrice);
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(putEditPriceSystem(payload));
  };

  const errorProvince =
    (errors &&
      errors.provinceId &&
      (errors.provinceId[0] ? errors.provinceId[0].message : errors.provinceId.message)) ||
    '';

  return (
    <>
      <BackPage
        title={!itemId ? lang.areaPriceSetting.addAreaPrice : lang.areaPriceSetting.editAreaPrice}
        handleBackPage={handleBackPage}
        marginbottom={1}
      />
      <Card sx={{ p: 2, minHeight: '475px' }}>
        {isLoading ? (
          <LoadingPage />
        ) : (
          <>
            <Grid container spacing={1} pl={isMobile ? 1 : 10}>
              <Grid container mt={2} mb={2} spacing={1} ml={0}>
                <Grid item xs={12} sm={3} md={3}>
                  <strong className="fz-14">
                    Khu vực:<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={12} sm={9} pr={1}>
                  <FormControl fullWidth>
                    <MultiSelectAutocomplete
                      name="provinceId"
                      control={control}
                      size="small"
                      errors={errorProvince}
                      options={customDataProvince(optionsDataApiProvince(dataApiProvince, isSouth))}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} md={3}></Grid>
                <Grid item xs={12} sm={9} pr={1}>
                  <i className="color-red note-edit-trip">* Có thể chọn nhiều khu vực cho phí hành trình</i>
                </Grid>
              </Grid>
              <Grid container mt={2} mb={2} spacing={1} ml={0}>
                <Grid item xs={4} sm={3} md={3}>
                  <strong className="fz-14">
                    Hạng xe:<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={8} sm={6} md={3} pr={1}>
                  <FormControl fullWidth>
                    <SelectSearch
                      options={pushAllOptions(ALL_VEHICLE, dataVehicle)}
                      name="vehicle"
                      control={control}
                      size="small"
                      errors={errors}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container mt={2} mb={2} spacing={1} ml={0}>
                <Grid item xs={4} sm={3} md={3}>
                  <strong className="fz-14">
                    0 - 4km (VNĐ):<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={8} sm={6} md={3} pr={1}>
                  <FormControl fullWidth>
                    <InputPriceForm
                      name="firstPrice"
                      control={control}
                      size="small"
                      fullWidth
                      arrayErrors={errors?.firstPrice}
                      multiline={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={2} mb={2} spacing={1} ml={0}>
                <Grid item xs={4} sm={3} md={3}>
                  <strong className="fz-14">
                    Trên 4km - {textConfigKm} (VNĐ):
                    <span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={8} sm={6} md={3} pr={1}>
                  <FormControl fullWidth>
                    <InputPriceForm
                      name="middlePrice"
                      control={control}
                      size="small"
                      fullWidth
                      arrayErrors={errors?.middlePrice}
                      multiline={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={2} mb={2} spacing={1} ml={0}>
                <Grid item xs={4} sm={3} md={3}>
                  <strong className="fz-14">
                    Trên {textConfigKm} (VNĐ):<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={8} sm={6} md={3} pr={1}>
                  <FormControl fullWidth>
                    <InputPriceForm
                      name="finalPrice"
                      control={control}
                      size="small"
                      fullWidth
                      arrayErrors={errors?.finalPrice}
                      multiline={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={2} mb={2} spacing={1} ml={0}>
                <Grid item xs={4} sm={3} md={3}>
                  <strong className="fz-14">
                    Phụ phí sau 23h và trước 06h (VNĐ):<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={8} sm={6} md={3} pr={1}>
                  <FormControl fullWidth>
                    <InputPriceForm
                      name="surcharge"
                      control={control}
                      size="small"
                      fullWidth
                      arrayErrors={errors?.surcharge}
                      multiline={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <HandleButtonAddEdit
              itemId={itemId}
              isEdit={true}
              handleSave={handleSubmit(handleSave)}
              handleCancel={handleCancel}
              onCancel={handleCancel}
              onSubmit={handleSubmit(onSubmit)}
              isNewData={isNewData}
            />
            <CustomBackdrop open={dataSelector?.loadingEditPriceSystem || dataSelector?.loadingAddPriceSystem} />
          </>
        )}
      </Card>
    </>
  );
}
